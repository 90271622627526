import React from "react";
import ReactDOM from "react-dom";
import "react-dates/initialize";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/browser";
import "./index.css";
import "react-dates/lib/css/_datepicker.css";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

Sentry.init({
  dsn: "https://0166dbb024ca42fb9ec4af1e0c046b49@sentry.io/1413893",
  environment: process.env.REACT_APP_ENV,
});

if (process.env.REACT_APP_ENV === "production") {
  TagManager.initialize({ gtmId: "GTM-KZXPXJP", dataLayerName: "dataLayer" });
}

const render = (Component) =>
  ReactDOM.render(<Component />, document.getElementById("root"));
render(App);

registerServiceWorker();

if (module.hot) {
  module.hot.accept("./App", () => render(require("./App").default));
}
